<template>
  <div>
    <el-card class="box-card">
      <div class="info">
        <div class="title">审核结果</div>
        <div v-if="detail.reviewStatus == 1 || detail.reviewStatus == 0" v-cloak class="itemWarp">
          <div class="item">
            <div class="itemTitle">审核时间:</div>
            <div class="itemText">{{ detail.reviewTime ? detail.reviewTime : '—' }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">审核结果:</div>
            <div class="itemText" :class="{ active: detail.reviewStatus == 1 }">
              <span v-if="detail.reviewStatus == 1">{{ filterStatus(detail.reviewStatus) }}</span>
              <span v-else>—</span>
            </div>
          </div>
        </div>
        <div v-if="detail.reviewStatus == 2" v-cloak class="itemWarp two">
          <div class="item">
            <div class="itemTitle">审核时间:</div>
            <div class="itemText">{{ detail.reviewTime }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">审核结果:</div>
            <div class="itemText" :class="{ red: detail.reviewStatus == 2 }">
              {{ filterStatus(detail.reviewStatus) }}
            </div>
          </div>
          <div class="item">
            <div class="itemTitle">未通过原因:</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="itemText fail" v-html="detail.reviewNote"></div>
          </div>
        </div>
      </div>
      <div class="info">
        <div class="title">基本信息</div>
        <div class="itemWarp two">
          <div class="item">
            <div class="itemTitle">证书名称:</div>
            <div class="itemText">{{ detail.certName }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">证书分类:</div>
            <div class="itemText">{{ detail.productAttributeCategoryName }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">证书等级:</div>
            <div class="itemText">{{ str }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">证书归属地:</div>
            <div class="itemText">{{ detail.provinceName }}- {{ detail.cityName }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">发证机构名称:</div>
            <div class="itemText">{{ detail.issuingAgency }}</div>
          </div>
          <div class="item">
            <div class="itemTitle">附件:</div>
            <div
              v-if="detail.certAttachList && detail.certAttachList.length > 0"
              class="itemText file"
            >
              <div v-for="(item, index) in detail.certAttachList" :key="index" class="fileWrap">
                <img :src="item.url" alt="" />
              </div>
            </div>
            <div v-else class="itemText">—</div>
          </div>
          <div class="item">
            <div class="itemTitle">证书申请表:</div>
            <div class="itemText yellow" @click="downloadFrom">下载申请表</div>
          </div>
          <div class="item">
            <div class="itemTitle">报名材料说明:</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="itemText tinymce" v-html="detail.applyMaterial"></div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { certificateDetail } from '@/api/certificate'
import to from 'await-to'
export default {
  data() {
    return {
      detail: {},
      str: '',
    }
  },
  created() {
    this.certificateDetail()
  },
  methods: {
    async certificateDetail() {
      const [res, err] = await to(certificateDetail({ certId: this.$route.query.certId }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      if (res.data.reviewStatus == 2) {
        res.data.reviewNote = res.data.reviewNote.replace(
          /<img/g,
          "<img style='max-width: 100% !important;'",
        )
      }
      res.data.applyMaterial = res.data.applyMaterial.replace(
        /<img/g,
        "<img style='max-width: 100% !important;'",
      )
      this.detail = res.data
      if (res.data.attrDict.specificationList[0].inputList.length !== 0) {
        this.str = res.data.attrDict.specificationList[0].inputList.map(v => v).join('、')
      } else {
        this.str = '—'
      }
    },
    async downloadFrom() {
      let src = this.detail.applyForm
      const response = await fetch(src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '申请表'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    filterStatus(val) {
      if (val == 0) {
        return '待审核'
      } else if (val == 1) {
        return '通过'
      } else {
        return '未通过'
      }
    },
  },
}
</script>

<style scoped lang="scss">
[v-cloak] {
  display: none !important;
}
.info {
  .title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 20px;
    color: #333333;
    background: #f6f6f6;
    padding-left: 20px;
    line-height: 42px;
  }
  .itemWarp {
    margin-top: 30px;
    padding-left: 20px;
    font-size: 14px;
    .item {
      margin-bottom: 30px;
      display: flex;
      .itemTitle {
        text-align: right;
      }
      .itemText {
        margin-left: 10px;
      }
      .fail {
        width: 535px;
        height: 280px;
        overflow-y: auto;
        border: 1px solid #ededed;
        border-radius: 2px;
      }
    }
  }
  .two {
    .item {
      margin-bottom: 24px;
      .itemTitle {
        width: 90px;
      }
    }
  }
  .file {
    display: flex;
    .fileWrap {
      background: #f5f5f5;
      width: 160px;
      height: 120px;
      padding: 2px 20px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tinymce {
    width: 820px;
    height: 300px;
    overflow-y: auto;
    border: 1px solid #ededed;
    opacity: 1;
    border-radius: 2px;
  }
  .active {
    color: #3fad92;
  }
  .red {
    color: #ff4646;
  }
  .yellow {
    color: #ff7b33;
    cursor: pointer;
  }
}
</style>
